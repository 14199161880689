import React from 'react';
import XMLValidator from './components/XMLValidator';

function App() {
  return (
    <div className="App">
      <XMLValidator />
    </div>
  );
}

export default App;