import React, { useState, useCallback, useEffect } from 'react';
import { AlertCircle, CheckCircle, Upload, Sun, Moon } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from './ui/alert';
import { Button } from './ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from './ui/card';
import { Progress } from './ui/progress';
import { useDropzone } from 'react-dropzone';
import confetti from 'canvas-confetti';

const mockValidateXMLSignature = (xmlContent) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const success = Math.random() > 0.5;
      if (success) {
        resolve({
          success: true,
          certificateDetail: {
            organization: "Example Org",
            issuer: "Example CA",
            validTo: "2025-12-31"
          }
        });
      } else {
        resolve({
          success: false,
          message: "Invalid signature. Please ensure the XML file contains a valid digital signature."
        });
      }
    }, 2000);
  });
};

const XMLValidator = () => {
  const [file, setFile] = useState(null);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [darkMode, setDarkMode] = useState(() => {
    return localStorage.getItem('darkMode') === 'true';
  });
  const [filePreview, setFilePreview] = useState('');

  useEffect(() => {
    document.documentElement.classList.toggle('dark', darkMode);
    localStorage.setItem('darkMode', darkMode);
  }, [darkMode]);

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
    setResult(null);
    const reader = new FileReader();
    reader.onload = (e) => {
      setFilePreview(e.target.result.slice(0, 200) + '...');
    };
    reader.readAsText(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({ 
    onDrop, 
    accept: { 'application/xml': ['.xml'] },
    maxFiles: 1
  });

  const handleValidation = async () => {
    if (!file) return;

    setLoading(true);
    setProgress(0);
    const reader = new FileReader();
    reader.onload = async (e) => {
      const xmlContent = e.target.result;
      try {
        for (let i = 0; i <= 100; i += 10) {
          setProgress(i);
          await new Promise(resolve => setTimeout(resolve, 200));
        }
        const validationResult = await mockValidateXMLSignature(xmlContent);
        setResult(validationResult);
        if (validationResult.success) {
          confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 }
          });
        }
      } catch (error) {
        setResult({ success: false, message: "Error processing file. Please try again." });
      }
      setLoading(false);
    };
    reader.readAsText(file);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-100 to-purple-100 dark:from-gray-800 dark:to-blue-900 p-4 transition-colors duration-500">
      <div className="w-full max-w-4xl animate-fadeIn">
        <Card className="bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm shadow-lg rounded-xl transition-all duration-300 hover:shadow-xl">
          <CardHeader className="relative">
            <Button 
              onClick={toggleDarkMode} 
              className="absolute top-2 right-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-200"
              aria-label="Toggle dark mode"
            >
              {darkMode ? <Sun className="h-4 w-4" /> : <Moon className="h-4 w-4" />}
            </Button>
            <CardTitle className="text-4xl font-bold text-center text-gray-800 dark:text-white mb-2">XML Signature Validator</CardTitle>
            <CardDescription className="text-center text-gray-600 dark:text-gray-300 text-lg">
              Upload an XML file to validate its digital signature
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-6">
            <div 
              {...getRootProps()} 
              className={`flex items-center justify-center w-full h-64 border-2 border-dashed rounded-lg cursor-pointer transition-all duration-300 ${
                isDragActive 
                  ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/50' 
                  : isDragReject
                    ? 'border-red-500 bg-red-50 dark:bg-red-900/50'
                    : `${darkMode ? 'border-gray-600 bg-gray-700/50' : 'border-gray-300 bg-gray-50/50'} hover:bg-gray-100 dark:hover:bg-gray-700/70`
              }`}
            >
              <input {...getInputProps()} />
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <Upload className={`w-16 h-16 mb-3 ${darkMode ? 'text-gray-400' : 'text-gray-500'} ${file ? 'text-green-500' : ''} transition-colors duration-300`} />
                <p className="mb-2 text-sm text-gray-600 dark:text-gray-300">
                  <span className="font-semibold">Click to upload</span> or drag and drop
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">XML file only</p>
              </div>
            </div>
            {file && (
              <div className="text-sm text-gray-600 dark:text-gray-300">
                <p className="font-semibold">Selected file: {file.name}</p>
                <p className="mt-2">Preview:</p>
                <pre className="bg-gray-100 dark:bg-gray-700 p-2 rounded mt-1 overflow-x-auto">
                  {filePreview}
                </pre>
              </div>
            )}
            <Button 
              onClick={handleValidation} 
              disabled={!file || loading}
              className={`w-full bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-semibold py-3 px-4 rounded-lg transition-all duration-300 transform hover:scale-105 ${file && !loading ? 'animate-pulse' : ''}`}
            >
              {loading ? 'Validating...' : 'Validate Signature'}
            </Button>
            {loading && (
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div 
                  className="bg-blue-600 h-2.5 rounded-full transition-all duration-500 ease-out"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            )}
            {result && (
              <Alert variant={result.success ? "default" : "destructive"} className="rounded-lg shadow-md">
                {result.success ? <CheckCircle className="h-5 w-5 text-green-500" /> : <AlertCircle className="h-5 w-5 text-red-500" />}
                <AlertTitle className="font-semibold">{result.success ? "Valid Signature" : "Invalid Signature"}</AlertTitle>
                <AlertDescription>
                  {result.success ? (
                    <div className="text-sm text-gray-600 dark:text-gray-300 space-y-1">
                      <p>Organization: {result.certificateDetail.organization}</p>
                      <p>Issuer: {result.certificateDetail.issuer}</p>
                      <p>Valid To: {result.certificateDetail.validTo}</p>
                    </div>
                  ) : (
                    <p className="text-sm text-red-600 dark:text-red-400">{result.message}</p>
                  )}
                </AlertDescription>
              </Alert>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default XMLValidator;